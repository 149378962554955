import { Box, Flex } from 'rebass';
import Head from 'next/head';
import { dynamoose } from '@apuyou/fotokorner-lib';

import Photo from 'components/photo';
import Container from 'components/container';
import { getDomain } from 'lib/domain';
import { getStudioAttribute } from 'lib/studio';
import { getStudioGalleries } from 'lib/gallery';
import { getPhoto } from 'lib/photo';
import Banner from 'components/banner';

const COVER_RATIO = 1.6;
const COVER_WIDTH = 240;

export default function Home({ studio }) {
  const galleries = studio.galleries;

  if (!studio) {
    const message = 'Sorry, we could not find this studio :(';

    return (
      <>
        <Banner studio={studio} banner={studio.banner} />
        <Container>
          <Head>
            <title>fotokorner</title>
          </Head>
          <Box>{message}</Box>
        </Container>
      </>
    );
  }

  const items = galleries.map((gallery) => ({
    key: gallery.id,
    component: (
      <>
        <Photo
          item={gallery.cover}
          width={COVER_WIDTH}
          ratio={COVER_RATIO}
          sizes={`(max-width: 40em) 100vw, ${COVER_WIDTH}px`}
          href={`/${gallery.id}`}
        />
        <Box
          sx={{
            color: 'white',
            fontSize: 4,
            fontWeight: 'heading',
            textDecoration: 'none',
            position: 'absolute',
            bottom: 0,
            left: 0,
            padding: 3,
            marginLeft: 3,
          }}
        >
          {gallery.title}
        </Box>
      </>
    ),
  }));

  return (
    <>
      <Head>
        <title>{studio.name} | fotokorner</title>
      </Head>
      <Banner studio={studio} banner={studio.banner} />
      <Container>
        <Flex flexDirection={['column', 'row']} flexWrap="wrap">
          {items.map((item) => (
            <Box
              key={item.key}
              width={[1, 1 / 4]}
              sx={{
                padding: 1,
                position: 'relative',
              }}
            >
              {item.component}
            </Box>
          ))}
        </Flex>
      </Container>
    </>
  );
}

export async function getServerSideProps({ req, res }) {
  dynamoose.aws.sdk.config.update({
    accessKeyId: process.env.STUDIO_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.STUDIO_AWS_SECRET_ACCESS_KEY,
    region: process.env.STUDIO_AWS_REGION,
  });

  res.setHeader('Cache-Control', 'public, s-maxage=1, stale-while-revalidate');

  const hostname = req.headers.host;

  const domain = (await getDomain(null, { domain: hostname }, null))?.toJSON();
  if (!domain) {
    return {
      notFound: true,
    };
  }

  const studio = (await getStudioAttribute('studioId')(domain)).toJSON();
  const galleries = (await getStudioGalleries(studio)).toJSON();

  return {
    props: {
      studio: {
        id: studio.id,
        name: studio.name,
        banner: studio.bannerPhotoId
          ? await getPhoto(studio.bannerPhotoId)
          : null,
        avatar: studio.avatarPhotoId
          ? await getPhoto(studio.avatarPhotoId)
          : null,
        galleries: await Promise.all(
          galleries.map(async (gallery) => ({
            ...gallery,
            cover: await getPhoto(gallery.coverPhotoId),
          }))
        ),
      },
    },
  };
}
